export const TYPES_LINKS = [
  {
    id: 1,
    name: 'Drive',
    icon: 'mdi-google-drive'
  },
  {
    id: 2,
    name: 'SmartSheet',
    icon: 'mdi-marker-check'
  },
  {
    id: 3,
    name: 'Otro',
    icon: 'mdi-adjust'
  }
]
