<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      @keydown="closeModalAddLinks"
      @click:outside="closeModalAddLinks"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Añadir nuevo link</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.type_document_id.$model"
                  :error="$v.form.type_document_id.$invalid && submitUpload"
                  :items="typeDocuments"
                  label="Tipo de documento"
                  item-text="nombre_tipo_documento"
                  clearable
                  item-value="tipo_documento_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.name_document_id.$model"
                  :error="$v.form.name_document_id.$invalid && submitUpload"
                  clearable
                  :items="nameDocuments"
                  item-text="nombre_documento"
                  item-value="nombre_documento_id"
                  label="Nombre del documento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  v-model="$v.form.link.$model"
                  :error="$v.form.link.$invalid && submitUpload"
                  clearable
                  label="Link"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-textarea
                  v-model="$v.form.comment.$model"
                  rows="3"
                  clearable
                  label="Comentario"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-autocomplete
                  v-model="$v.form.type_link.$model"
                  :error="$v.form.type_link.$invalid && submitUpload"
                  :items="TYPES_LINKS"
                  label="Tipo de link"
                  clearable
                  item-text="name"
                  item-value="id"
                >
                  <template #item="{ item }">
                    <v-avatar left>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-avatar>
                    {{ item.name }}
                  </template>
                  <template #selection="{ item }">
                    <v-avatar left>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-avatar>
                    {{ item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color="red darken-1"
            outlined
            text
            @click="closeModalAddLinks"
          >
            <small>
              Cancelar
            </small>
          </v-btn>
          <v-btn
            color="blue darken-3"
            @click="handleSubmit"
            :disabled="loading"
            :loading="loading"
          >
            <small class="text-white">
              Añadir link
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { authUsuarioComputed } from '@/state/helpers'
import swal2 from 'sweetalert2'
import { TYPES_LINKS } from '@/constants/link'
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false
    },
    closeModalAddLinks: {
      type: Function,
      required: true,
      default: () => {}
    }
  },
  validations: {
    form: {
      type_document_id: { required },
      name_document_id: { required },
      link: { required },
      type_link: { required },
      comment: {}
    }
  },
  data() {
    return {
      TYPES_LINKS,
      loading: false,
      submitUpload: false,
      form: {
        type_document_id: null,
        name_document_id: null,
        link: '',
        type_link: null,
        comment: ''
      }
    }
  },
  methods: {
    ...mapActions({
      addLink: 'link/addLink'
    }),
    resetForm() {
      this.form = {
        type_document_id: null,
        name_document_id: null,
        link: '',
        type_link: null,
        comment: ''
      }
      this.submitUpload = false
    },
    async handleSubmit() {
      this.submitUpload = true
      if (!this.$v.form.$invalid) {
        this.loading = true
        const resp = await this.addLink({
          ...this.form,
          place_code: this.$route.params.codigoLugarInstalacion,
          user: this.user.email
        })
        if (resp.status === 200) {
          swal2.fire({
            icon: 'success',
            title: 'Excelente',
            text: 'Link agregado correctamente',
            position: 'top-end',
            showConfirmButton: false,
            toast: true,
            timer: 5000,
            timerProgressBar: true
          })
          this.$emit('linkAdded')
          this.closeModalAddLinks()
          this.resetForm()
        }
      }
      this.loading = false
    }
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      nameDocuments: 'nombreDocumento/nombreDocumentos',
      typeDocuments: 'tipoDocumento/tipoDocumentos'
    })
  }
}
</script>

<style></style>
