<template>
  <main class="px-3">
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h4 class="ml-1 mb-4 font-weight-bold">
        Links
      </h4>
    </section>
    <v-row align-content="center" align-md="center" class="mt-3">
      <v-col cols="12" md="3" sm="12" class="py-1">
        <label for="filter">
          Busqueda
        </label>
        <v-text-field
          label="Busqueda"
          v-model="filters.desc_filter"
          @input="getLinksData(filters)"
          clearable
          placeholder="Buscar link"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="py-1">
        <label for="type_document">
          Tipo de documento
        </label>
        <v-autocomplete
          v-model="filters.type_filter"
          :items="typeDocuments"
          @change="($event) => getLinksData(filters)"
          clearable
          item-text="nombre_tipo_documento"
          item-value="nombre_tipo_documento"
          searchable
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="py-1">
        <label for="name_document">
          Nombre del documento
        </label>
        <v-autocomplete
          v-model="filters.name_filter"
          :items="nameDocuments"
          @change="($event) => getLinksData(filters)"
          clearable
          item-text="nombre_documento"
          item-value="nombre_documento"
          searchable
          class=""
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="3" sm="12" class="py-1">
        <label for="link_filter">
          Tipo de link
        </label>
        <v-autocomplete
          v-model="filters.link_filter"
          :items="TYPES_LINKS"
          @change="($event) => getLinksData(filters)"
          clearable
          item-text="name"
          item-value="name"
          searchable
          class=""
          label="Todos"
        >
          <template #item="{ item }">
            <v-avatar left>
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
            {{ item.name }}
          </template>
          <template #selection="{ item }">
            <v-avatar left>
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
            {{ item.name }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="wrappedLinks"
      :items-per-page="10"
      item-key="id"
      :show-select="false"
      :loading="loading"
      no-data-text="Sin elementos actualmente"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="rolModuloPermiso.escritura"
            color="blue darken-3"
            @click="openModalAddLinks"
            :dark="false"
            size="small"
          >
            <span class="text-white">
              Agregar Link
            </span>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top v-if="rolModuloPermiso.escritura">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteItem(item)">
                <v-icon small>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <p class="mt-2">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </p>
      </template>
      <template v-slot:item.url="{ item }">
        <a :href="item.url" target="_blank" class="text-left mt-2">
          {{ item.url }}
        </a>
      </template>
      <template v-slot:item.url_type="{ item }">
        <v-avatar left class="">
          <v-icon>{{ getIconTypeLink(item.url_type.id) }}</v-icon>
        </v-avatar>
        {{ item.url_type.name }}
      </template>
    </v-data-table>
    <add-link
      @linkAdded="getLinksData(filters)"
      :modalAction="dialogAddLinks"
      :closeModalAddLinks="closeModalAddLinks"
    ></add-link>
  </main>
</template>

<script>
import { proyectoMethods, proyectoComputed } from '@/state/helpers'
import { authMethods, authUsuarioComputed } from '@/state/helpers'
import { permiso } from '@/helpers/authservice/obtener-permisos'
import { mapActions, mapGetters } from 'vuex'
import { TYPES_LINKS } from '@/constants/link'
import { preventCloseIfNotKeyEventEsc } from '@/helpers/common'
import AddLink from './components/AddLink.vue'
import swal2 from 'sweetalert2'
export default {
  components: { AddLink },
  data() {
    return {
      TYPES_LINKS,
      dialogAddLinks: false,
      debounce: null,
      debounceTime: 500,
      filters: {
        type_filter: null,
        name_filter: null,
        desc_filter: null,
        link_filter: null
      },
      tableHeaders: [
        {
          text: 'Tipo de link',
          align: 'center',
          sortable: false,
          value: 'url_type'
        },

        {
          text: 'Tipo de documento',
          sortable: false,
          align: 'center',
          value: 'document_type.name'
        },
        {
          text: 'Link',
          align: 'center',
          sortable: false,
          value: 'url'
        },
        {
          text: 'Nombre documento',
          align: 'center',
          sortable: false,
          value: 'document_name.name'
        },
        {
          text: 'Comentario',
          align: 'center',
          sortable: false,
          value: 'comment'
        },
        {
          text: 'Fecha',
          align: 'center',
          sortable: false,
          value: 'created_at'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      //Data a mover a mixin
      proyectoSeleccionado: {},
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      lugarInstalacion: {},
      rolModuloPermiso: {}
    }
  },
  async mounted() {
    await this.obtenerProyectoUsuario()
    this.codigoProyecto = this.$route.params.codigoProyecto
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codigoLugarInstalacion,
      codigo_proyecto: this.codigoProyecto
    }
    let permisos = permiso.obtenerPermisos(this.user, this.$router.currentRoute.name)
    this.rolModuloPermiso = permisos
    this.getTypeDocuments()
    this.getNameDocuments()
    this.getLinksData({})
  },
  methods: {
    ...proyectoMethods,
    ...authMethods,
    ...mapActions({
      getLinks: 'link/getLinks',
      getTypeDocuments: 'tipoDocumento/obtenerTipoDocumentos',
      getNameDocuments: 'nombreDocumento/obtenerNombreDocumentos',
      deleteLink: 'link/deleteLink'
    }),
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.proyectoSeleccionado = res.body
        })
        .catch((error) => {})
    },
    async getLinksData(filters) {
      clearTimeout(this.debounce)
      let self = this
      this.debounce = setTimeout(async function() {
        self.getLinks({
          place_code: self.codigoLugarInstalacion,
          ...filters
        })
      }, self.debounceTime)
    },
    openModalAddLinks() {
      this.dialogAddLinks = true
    },
    closeModalAddLinks($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event)
      if (validationEvent) {
        this.dialogAddLinks = false
      }
    },
    getIconTypeLink(url_type_id) {
      return TYPES_LINKS.find((item) => item.id === url_type_id).icon
    },
    deleteItem({ id, document_name }) {
      let self = this
      this.$swal
        .fire({
          icon: 'error',
          title: `Deseas eliminar este link: ${document_name.name} ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Eliminar',
          cancelButtonText: `Cancelar`
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.deleteLink({
              document_id: id,
              user: self.user.email
            })
            if (resp.status === 200) {
              swal2.fire({
                icon: 'success',
                title: 'Excelente',
                text: 'Link eliminado correctamente',
                position: 'top-end',
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true
              })
              self.getLinksData(self.filters)
            }
          }
        })
    }
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
    ...mapGetters({
      links: 'link/links',
      loading: 'link/loading',
      nameDocuments: 'nombreDocumento/nombreDocumentos',
      typeDocuments: 'tipoDocumento/tipoDocumentos'
    }),
    wrappedLinks() {
      return this.links.filter((item) => item.status)
    }
  }
}
</script>

<style></style>
